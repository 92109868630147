import React, { useEffect, useState } from 'react'
import Text from '@embracesbs/component-text'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield'
import Container from '@embracesbs/component-container/Container'
import Button2 from '@embracesbs/component-button2/Button2'
import Loader from '../Loader/Loader'
import { useTenants } from '../Context/TenantContext'

interface tenantSettingsProps {}

const Settings: React.FC<tenantSettingsProps> = ({}) => {
  const { callApiWithToken } = useApiCall()

  const { selectedTenant } = useTenants()
  const [tenantDisplayname, setTenantDisplayname] = useState<string>('')

  useEffect(() => {
    if (selectedTenant) {
      setTenantDisplayname(selectedTenant.tenantDisplayname)
    }
  }, [selectedTenant])

  const handleUpdate = () => {
    callApiWithToken({
      endpoint: `/api/tenantsconfiguration/${selectedTenant?.id}/launch`,
      method: 'PUT',
      body: { TenantDisplayname: tenantDisplayname }
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantDisplayname(e.target.value)
  }

  if (selectedTenant) {
    return (
      <>
        <Container justify='flex-end'>
          <Button2 buttonRadius='8px' buttonSize='40px' text={'Update Tenant Properties'} onClick={handleUpdate} variant='contained' />
        </Container>
        <Container justify='flex-start'>
          <Text textStyle='h6'>Tenant Properties</Text>
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Display name'}>
            <TextField value={tenantDisplayname} onChange={handleInputChange} type='text' variant='default' />
          </TextFieldWrapper>
        </Container>
      </>
    )
  } else {
    return <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Loading tenant deploy configuration'></Loader>
  }
}

export default Settings
