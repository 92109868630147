import React, { useState, useEffect } from 'react'
import Container from '@embracesbs/component-container/Container'
import Sidebar2 from '@embracesbs/component-sidebar2/Sidebar2'
import SidebarItem from '@embracesbs/component-sidebaritem/SidebarItem'
import Badge from '@embracesbs/component-badge/Badge'
import { Link } from 'react-router-dom'
import { useAccessToken } from '../../Utilities/Auth/AuthService'
import { useApiCall } from '../../Utilities/ApiService/ApiService'

const MainMenu: React.FC = () => {
  const [segments, setSegments] = useState([])
  const accesToken = useAccessToken()
  const { callApiWithToken } = useApiCall()

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await callApiWithToken({
          endpoint: `/api/featureflags/segments`,
          method: 'GET'
        })
        if (response) setSegments(response)
      } catch (error) {
        console.error('Error fetching segments:', error)
      }
    }

    if (accesToken && segments.length === 0) {
      fetchSegments()
    }
  }, [accesToken])

  return (
    <Sidebar2 style={{ overflow: 'visible', zIndex: 2 }}>
      <Container isVertical isFullHeight>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
            width: '100%'
          }}
        >
          <Link to={`/`} key={'dashboard'} style={{ textDecoration: 'none' }}>
            <SidebarItem id={'dashboard'} title={'dashboard'} icon={{ iconName: 'AppSocial' }} style={{ wordSpacing: 10 }} />
          </Link>

          {segments?.length > 0 &&
            segments.map((segment: string) => (
              <Link to={`/feature-flags/${segment}`} key={segment} style={{ textDecoration: 'none' }}>
                <SidebarItem
                  id={segment}
                  title={segment}
                  icon={{ iconName: 'FlagPlain3' }}
                  badge={<Badge text={segment.slice(0, 3).toUpperCase()} />}
                  style={{ wordSpacing: 10 }}
                />
              </Link>
            ))}
        </section>
      </Container>
    </Sidebar2>
  )
}

export default MainMenu
