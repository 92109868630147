import React, { useEffect, useState } from 'react'
import { PostTenant } from '../../../Interfaces/Types'
import Modal from '@embracesbs/component-modal'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield/TextField'
import Container from '@embracesbs/component-container/Container'
import { useConfig } from '../../Config/ConfigContext'
import Autocomplete from '@embracesbs/component-autocomplete'
import { useApiCall } from '../../../Utilities/ApiService/ApiService'
import { useSubscriptions } from '../../Context/SubscriptionContext'

interface AddTenantProps {
  isOpen: boolean
  onClosed: () => void
  onCreated: (tenant?: any) => void
}

interface EnviromentOptions {
  label: string
  value: string
}

const AddTenant: React.FC<AddTenantProps> = ({ isOpen, onClosed, onCreated }) => {
  const config = useConfig()
  const { callApiWithToken } = useApiCall()
  const [tenantName, setTenantName] = useState<string>('')
  const [tenantDisplayName, setTenantDisplayName] = useState<string>('')
  const [environment, setEnvironment] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { selectedSubscription } = useSubscriptions()

  const [environmentOptions, setEnvironmentOptions] = useState<EnviromentOptions[]>([])

  useEffect(() => {
    if (config && config.environments) {
      const options: EnviromentOptions[] = config.environments.map((environment) => ({
        label: environment,
        value: environment
      }))
      setEnvironmentOptions(options)
    }
  }, [config])

  const handleAddTenant = async () => {
    //Later some better validation
    if (tenantName === '') {
      alert('please add tenantName')
      return
    }

    if (tenantDisplayName === '') {
      alert('please add tenantdisplayName')
      return
    }

    if (environment === '') {
      alert('please add environment')
      return
    }

    const tenant: PostTenant = {
      tenantDisplayname: tenantDisplayName,
      tenantName: tenantName,
      environment: environment
    }

    try {
      const data = await callApiWithToken({
        endpoint: `/api/subscription/${selectedSubscription.id}/tenant`,
        method: 'POST', // Your request payload
        body: tenant
      })
      onCreated(data)
      onClosed()
    } catch (error: any) {
      if (error.response && error.response.status === 400 && error.response.data) {
        setErrorMessage(error.response.data)
      } else {
        setErrorMessage('An unexpected error occurred.')
      }
    }
  }

  const handleClear = () => {
    setErrorMessage('')
  }

  return (
    <Modal
      isOpen={isOpen}
      width='50%'
      onClose={() => onClosed()}
      hasHeaderBorder={true}
      headerTitle={`Add Tenant in ${selectedSubscription?.customerName}`}
      height={'60%'}
      onConfirm={handleAddTenant}
      variant='prompt'
      labelConfirm='Save'
    >
      <Container isVertical>
        <TextFieldWrapper labelText='Tenant Name'>
          <TextField
            placeholder='Tenant Name'
            value={tenantName}
            onChange={(e) => setTenantName(e.target.value)}
            hasAnError={errorMessage !== ''}
            errorMessage={errorMessage}
            onClear={handleClear}
          />
        </TextFieldWrapper>

        <TextFieldWrapper labelText='Tenant Display Name'>
          <TextField placeholder='Tenant Display Name' value={tenantDisplayName} onChange={(e) => setTenantDisplayName(e.target.value)} />
        </TextFieldWrapper>
        <TextFieldWrapper labelText='Enviroment'>
          <Autocomplete onChange={(e) => setEnvironment(e.value)} options={environmentOptions} />
        </TextFieldWrapper>
      </Container>
    </Modal>
  )
}

export default AddTenant
