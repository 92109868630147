import React from 'react'
import Text from '@embracesbs/component-text'
import { Link } from 'react-router-dom'
import { useTenants } from '../Context/TenantContext'
import { useSubscriptions } from '../Context/SubscriptionContext'

const Breadcrumbs: React.FC = () => {
  const { selectedTenant, setSelectedTenantId } = useTenants()
  const { selectedSubscription, setSelectedSubscriptionId } = useSubscriptions()
  const linkStyle = {
    cursor: 'pointer'
  }

  return (
    <Text>
      <div>
        <Link to={`/subscriptions`} style={{ textDecoration: 'none' }}>
          <span
            className='breadcrumb-link'
            style={linkStyle}
            onClick={() => {
              setSelectedSubscriptionId(null)
              setSelectedTenantId(null)
            }}
          >
            Subscriptions /{' '}
          </span>
        </Link>
        {selectedSubscription && (
          <Link to={`/subscriptions/${selectedSubscription.id}`} style={{ textDecoration: 'none' }}>
            <span className='breadcrumb-link' style={linkStyle}>
              {selectedSubscription.customerName} /{' '}
            </span>
          </Link>
        )}
        {selectedSubscription && selectedTenant && (
          <Link to={`/subscriptions/${selectedSubscription.id}/tenant/${selectedTenant.id}`} style={{ textDecoration: 'none' }}>
            <span className='breadcrumb-link' style={linkStyle}>
              {selectedTenant.tenantDisplayname}
            </span>
          </Link>
        )}
      </div>
    </Text>
  )
}

export default Breadcrumbs
