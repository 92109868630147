import React from 'react'
import Text from '@embracesbs/component-text'
import Divider from '@embracesbs/component-divider/Divider'
import { useTenants } from '../Context/TenantContext'

interface tenantExtendedDetailsProbs {}

const TenantExtendedDetails: React.FC<tenantExtendedDetailsProbs> = ({}) => {
  const { selectedTenant } = useTenants()

  if (!selectedTenant) {
    return null
  }

  return (
    <>
      <Text textStyle='h6'>Deploytime status</Text>

      <br></br>
      <Text textColor='grey' textStyle='description'>
        IsLocked:
      </Text>
      <Text textStyle='body'>{selectedTenant.activeReleaseLock.toString()}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        status:
      </Text>
      <Text textStyle='body'>{selectedTenant.deployStatus}</Text>
      <br></br>
      <Divider />
      <br></br>
      <Text textStyle='h6'>Tenant Properties</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        Name:
      </Text>
      <Text textStyle='body'>{selectedTenant.tenantName}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        Display name:
      </Text>
      <Text textStyle='body'>{selectedTenant.tenantDisplayname}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        Enviroment:
      </Text>
      <Text textStyle='body'>{selectedTenant.environment}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        CreatedBy:
      </Text>
      <Text textStyle='body'>{selectedTenant.createdBy}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        CreationTime:
      </Text>
      <Text textStyle='body'>{selectedTenant.creationTime}</Text>
      <br></br>
      {selectedTenant.modifiedBy && (
        <>
          <Text textColor='grey' textStyle='description'>
            LastModifiedBy:
          </Text>
          <Text textStyle='body'>{selectedTenant.modifiedBy}</Text>
          <br />
        </>
      )}
      <Text textColor='grey' textStyle='description'>
        PipelineUrl:
      </Text>
      <Text textStyle='body'>
        <a
          href={`https://dev.azure.com/embrace/Embrace%20Suite/_build?definitionId=${selectedTenant.azureDevopsBuildDefinitionId}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: '#1a73e8',
            textDecoration: 'underline'
          }}
        >
          Azure Devops Link
        </a>
      </Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        InternalId:
      </Text>
      <Text textStyle='body'>{selectedTenant.id}</Text>
      <br></br>
    </>
  )
}

export default TenantExtendedDetails
