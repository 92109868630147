import React, { useEffect } from 'react'
import PageLayout from '../Components/PageLayout/PageLayout'
import { SubscriptionProvider, useSubscriptions } from '../Components/Context/SubscriptionContext'
import { TenantProvider, useTenants } from '../Components/Context/TenantContext'
import TenantDetails from '../Components/Tenants/TenantDetails'
import TenantExtendedDetails from '../Components/Tenants/TenantExtendedDetails'
import SubscriptionExtendedDetails from '../Components/Subscriptions/SubscriptionExtendedDetails'
import { useParams } from 'react-router-dom'
import Section1 from '../Components/Section1/Section1'

const DashboardContent: React.FC = () => {
  const { subscriptionid, tenantid } = useParams<{ subscriptionid: string; tenantid: string }>()
  const { setSelectedSubscriptionId, selectedSubscriptionId } = useSubscriptions()
  const { setSelectedTenantId } = useTenants()

  useEffect(() => {
    if (subscriptionid) {
      setSelectedSubscriptionId(subscriptionid, false)
    }
  }, [subscriptionid, setSelectedSubscriptionId])

  useEffect(() => {
    if (tenantid && selectedSubscriptionId) {
      setSelectedTenantId(tenantid, false)
    }
  }, [tenantid, selectedSubscriptionId, setSelectedTenantId])

  return (
    <PageLayout
      section1Content={<Section1 />}
      section2Content={<TenantDetails />}
      section3Content={
        <>
          <TenantExtendedDetails />
          <SubscriptionExtendedDetails />
        </>
      }
    />
  )
}

const Dashboard: React.FC = () => {
  return (
    <SubscriptionProvider>
      <TenantProvider refreshInterval={10000}>
        <DashboardContent />
      </TenantProvider>
    </SubscriptionProvider>
  )
}
export default Dashboard
