import React, { useState, useEffect } from 'react'
import Button2 from '@embracesbs/component-button2/Button2'
import Icon from '@embracesbs/component-icon/Icon'
import { useConfig } from '../Config/ConfigContext'

interface ConnectivityCheckerProps {
  hostname?: string
  port?: string
}

const ConnectivityChecker: React.FC<ConnectivityCheckerProps> = ({ hostname, port = '443' }) => {
  const config = useConfig()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [statusText, setStatusText] = useState<string>('Test')

  const checkConnectivity = async () => {
    if (!hostname) return

    setIsLoading(true)
    setStatusText('Testing...')

    try {
      const response = await fetch(`${config?.host}/api/system/check?hostname=${hostname}&port=${port}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })

      const data = await response.json()

      if (data.success) {
        setStatusText(data.statusText)
      } else {
        setStatusText(data.statusText)
      }
    } catch (error) {
      setStatusText('Error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(false)
    setStatusText('Test')
  }, [hostname, port])

  const getIcon = () => {
    if (isLoading) {
      return <></>
    }

    if (statusText === 'Connected') {
      return <Icon className='launch-icon' color='white' iconName='Check1' size={10} />
    }

    if (statusText === 'Timed Out' || statusText === 'Failed') {
      return <Icon className='launch-icon' color='white' iconName='AlertTriangle' size={10} />
    }

    return <Icon className='launch-icon' color='white' iconName='PowerToolsDrillFilled' size={10} />
  }

  return (
    <Button2
      style={{ padding: 0 }}
      className='connectivity-button'
      buttonSize='40px'
      leftElement={getIcon()}
      tabIndex={-1}
      onClick={checkConnectivity}
      isLoading={isLoading}
      text={statusText}
      tooltip='Test connectivity from kubernetes to the provided hostname and port'
      type='button'
      isDisabled={!hostname}
    />
  )
}

export default ConnectivityChecker
