import React, { useContext, useCallback } from 'react'
import ListItem from '@embracesbs/component-listitem'
import Button2 from '@embracesbs/component-button2/Button2'
import { Tenant } from '../../Interfaces/Types'
import Container from '@embracesbs/component-container/Container'
import Icon from '@embracesbs/component-icon'
import { environmentLetters, itemBackgroundColor, itemColor } from '../../Utilities/helpers/helpers'
import { AuthRoleContext } from '../Auth/AuthRoleContext'
import { useTenants } from '../Context/TenantContext'
import SupportLoginQuickButton from '../Auth/SupportLoginQuickButton'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { useNavigate } from 'react-router-dom'
import QuickLaunchButton from './QuickLaunchButton'

interface TenantListItemProps {
  tenant: Tenant
}

const TenantListItem: React.FC<TenantListItemProps> = ({ tenant }) => {
  const roles = useContext(AuthRoleContext)
  const { selectedTenant } = useTenants()
  const { callApiWithToken } = useApiCall()
  const history = useNavigate()

  const handleSelect = useCallback((subscriptionId: string, tenantId: string) => {
    history('/subscriptions/' + subscriptionId + '/tenant/' + tenantId, { replace: true })
  }, [])

  const handleUpdate = (tenantId: string) => {
    tenant.deployStatus = 'feWaiting'
    callApiWithToken({
      endpoint: `/api/tenantsconfiguration/${tenantId}/launch`,
      method: 'PUT',
      body: {}
    })
      .then((response: any) => {
        console.log(response)
        tenant.deployStatus = 'updating'
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  const renderPendingIcon = (tenant: Tenant) => {
    return tenant.deployStatus === 'feWaiting' ? (
      <Icon
        color={itemColor(tenant, tenant.id === selectedTenant?.id)}
        iconName='EmailActionSync1'
        size={16}
        style={{
          margin: '0 9px 0 0 ',
          animation: 'spin 1s linear infinite'
        }}
      />
    ) : (
      <Icon color={itemColor(tenant, tenant.id === selectedTenant?.id)} iconName='InProgress' size={16} style={{ margin: '0 9px 0 0 ' }} />
    )
  }

  const renderListItem = () => {
    return (
      <ListItem
        id={tenant.id}
        key={tenant.id}
        visual={
          <div
            style={{
              marginLeft: '-12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div>
              <Icon color={itemColor(tenant, tenant.id === selectedTenant?.id)} iconName='DataFileGraph' size={10} />
            </div>
            <p>{environmentLetters(tenant)}</p>
          </div>
        }
        style={{
          border: '1px solid ' + itemColor(tenant, tenant.id === selectedTenant?.id),
          margin: '0 0 6px 0',
          background: itemBackgroundColor(tenant.id === selectedTenant?.id)
        }}
        subTitle={tenant.deployStatus}
        title={tenant.tenantName}
        preset='oneLineIcon'
        actions={
          <Container>
            <QuickLaunchButton tenant={tenant} />
            <SupportLoginQuickButton tenant={tenant} />
            <Button2
              buttonSize='30px'
              leftElement={<Icon color='#416eec' iconName='TaskOverview' size={10} />}
              tabIndex={-1}
              text='details'
              type='button'
              onClick={() => {
                handleSelect(tenant.subscriptionId, tenant.id)
              }}
              variant='subtle'
            />
          </Container>
        }
      />
    )
  }

  return <>{renderListItem()}</>
}

export default TenantListItem
