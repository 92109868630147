import React, { useEffect } from 'react'
import Autocomplete from '@embracesbs/component-autocomplete/Autocomplete'
import Header from '@embracesbs/component-header/Header'
import Button2 from '@embracesbs/component-button2/Button2'
import Container from '@embracesbs/component-container/Container'
import Divider from '@embracesbs/component-divider'
import Icon from '@embracesbs/component-icon/Icon'
import Toast, { customToast } from '@embracesbs/component-toast'
import { useTenants } from '../../Context/TenantContext'
import { useApiCall } from '../../../Utilities/ApiService/ApiService'
import { useAccessToken } from '../../../Utilities/Auth/AuthService'

interface CMSAccordionContentProps {}

const CMSAccordionContent: React.FC<CMSAccordionContentProps> = () => {
  const [selectedConversationPacks, setSelectedConversationPacks] = React.useState<{ label: string; value: string }[]>([])
  const { selectedTenantId, selectedTenant } = useTenants()
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()

  useEffect(() => {
    fetchConversationPacks()
  }, [accessToken])

  const fetchConversationPacks = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/appconfiguration/${selectedTenantId}/TenancySettings:Tenants:${selectedTenant.tenantName}:Packs/conversations`,
        method: 'GET'
      })
      if (response !== undefined) {
        setSelectedConversationPacks(conversationPackOptions.filter((option) => response.includes(option.value)))
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  const headerStyle = {
    fontFamily: 'F-Default',
    fontWeight: 'bold',
    width: '100%'
  }

  const sectionContainerStyle = {
    marginBottom: '25px',
    padding: '15px',
    background: '#f6f6f6',
    borderRadius: '8px'
  }
  const innerContainerStyle = {
    marginBottom: '0px'
  }

  const conversationPackOptions = [
    { label: 'Customers Pack', value: 'customers' },
    { label: 'Common Pack', value: 'common' }
  ]

  const handleConversationPackChoice = () => {
    let csvPacks // Convert the selected conversation packs to a comma separated string
    if (selectedConversationPacks.length > 0) {
      csvPacks = JSON.stringify(selectedConversationPacks.map((pack: any) => pack.value))
    }

    // Call the API to save the conversation packs
    callApiWithToken({
      endpoint: `/api/appconfiguration/${selectedTenantId}`,
      method: 'POST',
      body: { key: `TenancySettings:Tenants:${selectedTenant.tenantName}:Packs`, value: csvPacks, label: 'conversations' }
    })

    // When successful call the customToast.info function
    customToast.success('Conversation pack saved')
  }

  const renderConversationSettings = () => {
    return (
      <Container isVertical align='center' gap={0} spacing={0} isFullWidth style={sectionContainerStyle}>
        <Header style={headerStyle}>Conversations settings</Header>
        <Toast />
        <Container align='center' gap={0} spacing={0} isFullWidth style={innerContainerStyle}>
          <Autocomplete
            style={{ width: '100%' }}
            isSearchable={false}
            isMulti
            options={conversationPackOptions}
            value={selectedConversationPacks}
            onChange={setSelectedConversationPacks}
            placeholder='Select conversation pack'
          />
          <Button2
            buttonRadius='8px'
            buttonSize='40px'
            leftElement={<Icon iconName='OfficeFileDoc1' size={16} />}
            onClick={handleConversationPackChoice}
            text={`Save`}
            variant='contained'
            style={{ marginLeft: '10px', float: 'right' }}
          ></Button2>
        </Container>
      </Container>
    )
  }

  const renderMediaProviderSettings = () => {
    return (
      <Container isVertical align='center' gap={0} spacing={0} isFullWidth style={sectionContainerStyle}>
        <Header style={headerStyle}>Media Provider Settings</Header>
        <Container align='center' gap={0} spacing={0} isFullWidth style={innerContainerStyle}></Container>
      </Container>
    )
  }

  const renderContentProviderSettings = () => {
    return (
      <Container isVertical align='center' gap={0} spacing={0} isFullWidth style={sectionContainerStyle}>
        <Header style={headerStyle}>Content Provider Settings</Header>
        <Container align='center' gap={0} spacing={0} isFullWidth style={innerContainerStyle}></Container>
        <Divider />
      </Container>
    )
  }

  const renderPortalProviderSettings = () => {
    return (
      <Container isVertical align='center' gap={0} spacing={0} isFullWidth style={sectionContainerStyle}>
        <Header style={headerStyle}>Portal Provider Settings</Header>
        <Container align='center' gap={0} spacing={0} isFullWidth style={innerContainerStyle}></Container>
        <Divider />
      </Container>
    )
  }

  return <>{renderConversationSettings()}</>
}

export default CMSAccordionContent
