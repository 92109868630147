import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react'
import { useConfig } from '../Config/ConfigContext'
import { useAccessToken } from '../../Utilities/Auth/AuthService'
import { Subscription } from '../../Interfaces/Types'
import { useNavigate } from 'react-router-dom'

export const SubscriptionContext = createContext<any>({})

interface SubscriptionProviderProps {
  children: ReactNode
}

export function SubscriptionProvider({ children }: SubscriptionProviderProps) {
  const config = useConfig()
  const accessToken = useAccessToken()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string | null>(null)
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
  const history = useNavigate()

  useEffect(() => {
    async function fetchSubscriptions() {
      if (accessToken === null) {
        console.warn('Access token is null')
        setLoading(false)
        return
      }

      try {
        setLoading(true)
        console.log('Fetching subscriptions')
        const response = await fetch(`${config?.host}/api/subscription`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        })

        if (!response.ok) {
          throw new Error(`Error fetching subscriptions: ${response.statusText}`)
        }

        const data = await response.json()

        if (JSON.stringify(data) !== JSON.stringify(subscriptions)) {
          setSubscriptions(data)
        }
        console.log('Finished fetching subscriptions')
        setLoading(false)
      } catch (error) {
        console.error('Failed to fetch subscriptions', error)
      }
    }

    fetchSubscriptions()
  }, [accessToken, config?.host])

  useEffect(() => {
    if (selectedSubscriptionId) {
      const subscription = subscriptions.find((sub) => sub.id === selectedSubscriptionId) || null
      setSelectedSubscription(subscription)
    } else {
      setSelectedSubscription(null)
    }
  }, [selectedSubscriptionId, subscriptions])

  const addSubscription = (newSubscription: Subscription) => {
    setSubscriptions((prevSubscriptions) => [...prevSubscriptions, newSubscription])
    handleSetSelectedSubscriptionId(newSubscription.id)
  }

  const handleSetSelectedSubscriptionId = (id: string | null, updateHistory: boolean = true) => {
    setSelectedSubscriptionId(id)
    if (updateHistory) {
      if (id) {
        history(`/subscriptions/${id}`)
      } else {
        history(`/subscriptions/`)
      }
    }
  }

  const contextValue = useMemo(
    () => ({
      subscriptions,
      loading,
      selectedSubscriptionId,
      setSelectedSubscriptionId: handleSetSelectedSubscriptionId,
      selectedSubscription,
      addSubscription
    }),
    [subscriptions, loading, selectedSubscriptionId, selectedSubscription]
  )
  return <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>
}

export function useSubscriptions() {
  return useContext(SubscriptionContext)
}
