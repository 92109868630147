import Container from '@embracesbs/component-container/Container'
import React, { useEffect, useState, useContext } from 'react'
import Text from '@embracesbs/component-text'
import Icon from '@embracesbs/component-icon/Icon'
import { DeployStatus, TenantConfig } from '../../Interfaces/Types'
import Divider from '@embracesbs/component-divider/Divider'
import Switch from '@embracesbs/component-switch'
import Checkbox from '@embracesbs/component-checkbox'
import TextField from '@embracesbs/component-textfield'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import Loader from '../Loader/Loader'
import Button2 from '@embracesbs/component-button2/Button2'
import Autocomplete from '@embracesbs/component-autocomplete/Autocomplete'
import { AuthRoleContext } from '../Auth/AuthRoleContext'
import Modal from '@embracesbs/component-modal'
import Toast, { customToast } from '@embracesbs/component-toast'
import SupportLogin from '../Auth/SupportLogin'
import { useAccessToken } from '../../Utilities/Auth/AuthService'
import { useTenants } from '../Context/TenantContext'
import { useConfig } from '../Config/ConfigContext'
import ConnectivityChecker from './ConnectivityChecker'

interface TenantDeploymentSettingsProps { }

const TenantDeploymentSettings: React.FC<TenantDeploymentSettingsProps> = ({ }) => {
  const accessToken = useAccessToken()
  const config = useConfig()
  const { callApiWithToken } = useApiCall()
  const [tenantConfig, setTenantConfig] = useState<TenantConfig | undefined>(undefined)
  const [tenantConfigIsLoading, setTenantConfigIsLoading] = useState(true)
  const roles = useContext(AuthRoleContext)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { selectedTenantId, selectedTenant, setSelectedTenantId } = useTenants()
  const [isClicked, setIsClicked] = useState<boolean>(false)

  useEffect(() => {
    async function fetchTenantConfig() {
      if (!accessToken || selectedTenantId === null) {
        setTenantConfigIsLoading(false)
        return
      }

      try {
        setTenantConfigIsLoading(true)
        console.log('Fetching tenant config')
        const response = await fetch(`${config?.host}/api/tenant/${selectedTenantId}/tenantsconfiguration`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        })

        if (!response.ok) {
          throw new Error(`Error fetching tenant config: ${response.statusText}`)
        }

        const data = await response.json()
        setTenantConfig(data)
        console.log('Finished fetching tenant config')
      } catch (error) {
        console.error('Failed to fetch tenant config', error)
      } finally {
        setTenantConfigIsLoading(false)
      }
    }

    if (selectedTenantId !== null) {
      fetchTenantConfig()
    }
  }, [accessToken, selectedTenantId])

  const isUpdating = (state?: DeployStatus) => {
    if (state === 'updating') {
      return true
    }

    return false
  }

  const onModalClose = () => {
    setIsModalOpen(!isModalOpen)
  }
  const onDeleteConfirm = () => {
    setIsClicked(true)
    return new Promise(() => {
      callApiWithToken({
        endpoint: `/api/tenant/delete/pending/${selectedTenant?.id}`,
        method: 'PUT'
      })
        .then(() => {
          setSelectedTenantId(null)
        })
        .catch((error: Error) => {
          setIsClicked(false)
          console.error(error)
        })
    })
  }

  const handleTenantConfigurationChange = (name: string, value: any) => {
    setTenantConfig((prevConfig) => {
      // Return early if prevConfig is undefined for some reason
      if (!prevConfig) return prevConfig

      // Here we check if `name` is a key of TenantConfig to satisfy TypeScript
      if (name in prevConfig) {
        return {
          ...prevConfig,
          [name]: value // Assuming you want to store "true"/"false" strings
        } as TenantConfig // Explicitly cast the return type as TenantConfig
      }
      return prevConfig
    })
  }

  const handleDelete = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleUpdate = async () => {
    if (!accessToken) {
      console.error('Access token is null')
      return
    }

    if (selectedTenantId) {
      selectedTenant.deployStatus = 'updating'
    }

    try {
      console.log('Updating tenant config')
      const response = await fetch(`${config?.host}/api/tenant/${selectedTenantId}/tenantsconfiguration`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(tenantConfig)
      })

      if (response.status === 202) {
        customToast.success('Accepted')

        console.log('Tenant config update accepted')
      } else if (!response.ok) {
        throw new Error(`Error updating tenant config: ${response.statusText}`)
      }

      console.log('Finished updating tenant config: ')
    } catch (error: any) {
      customToast.error(error.message)
      console.error('Failed to update tenant config', error)
    }
  }

  if (tenantConfigIsLoading) {
    return <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Loading tenant deploy configuration'></Loader>
  }

  return (
    <>
      <Container style={{ paddingBottom: '30px' }}>
        <Container justify='flex-start'>
          <Text textStyle='h6'>General Settings</Text>
        </Container>
        <Toast />
        <Container justify='flex-end'>
          {roles?.isAdmin && (
            <Button2
              buttonRadius='8px'
              buttonSize='40px'
              text={'Delete Tenant'}
              isDanger={!isUpdating(selectedTenant?.deployStatus)}
              leftElement={<Icon iconName='Delete1' size={16} />}
              onClick={() => handleDelete()}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              variant='contained'
            />
          )}
          {selectedTenant?.deployStatus !== 'initializing' ? <SupportLogin tenant={selectedTenant}></SupportLogin> : <></>}
          <Button2
            style={{ minWidth: '150px' }}
            buttonRadius='8px'
            buttonSize='40px'
            text={isUpdating(selectedTenant?.deployStatus) ? 'Updating...' : 'Update tenant'}
            isDisabled={isUpdating(selectedTenant?.deployStatus)}
            onClick={() => handleUpdate()}
            variant='contained'
          />
        </Container>
      </Container>

      <div style={{ position: 'relative', maxHeight: '70%', overflow: 'auto', paddingRight: '15px' }}>
        <Container isVertical>
          <Container>
            <TextFieldWrapper labelText={'Suite Hostname'} style={{ flex: 3 }}>
              <TextField
                value={tenantConfig?.feHostname}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('feHostname', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Suite Runmode'} style={{ flex: 1 }}>
              <Autocomplete
                value={tenantConfig?.feRunMode}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('feRunMode', e.value)}
                options={[
                  {
                    label: 'Full',
                    value: 'Full'
                  },
                  {
                    label: 'NoCustomers',
                    value: 'NoCustomers'
                  },
                  {
                    label: 'CustomersOnly',
                    value: 'CustomersOnly'
                  }
                ]}
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Language'}>
              <Autocomplete
                value={tenantConfig?.feLanguage}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('feLanguage', e.value)}
                options={[
                  {
                    label: 'nl',
                    value: 'nl'
                  },
                  {
                    label: 'en',
                    value: 'en'
                  }
                ]}
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Language Code (nl-NL, en-US, etc)'}>
              <TextField
                value={tenantConfig?.feLanguageCode}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('feLanguageCode', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>Authentication Settings</Text>
        </Container>
        <Container isVertical>
          <Container>
            <TextFieldWrapper labelText={'Authentication Type'}>
              <Autocomplete
                value={tenantConfig?.authenticationType}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('authenticationType', e.value)}
                options={[
                  {
                    label: 'AzureAd',
                    value: 'AzureAd'
                  },
                  {
                    label: 'Local',
                    value: 'local'
                  }
                ]}
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Tenant Id'}>
              <TextField
                value={tenantConfig?.idpAzureAdTenantId}
                isDisabled={tenantConfig?.authenticationType !== 'AzureAd' || isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('idpAzureAdTenantId', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Client ID'}>
              <TextField
                value={tenantConfig?.clientId}
                isDisabled={tenantConfig?.authenticationType !== 'AzureAd' || isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('clientId', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Client Secret'}>
              <TextField
                isDisabled={tenantConfig?.authenticationType !== 'AzureAd' || isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.clientSecret}
                onChange={(e) => handleTenantConfigurationChange('clientSecret', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Keycloak support account password'} style={{ flex: 2 }}>
              <TextField
                value={tenantConfig?.supportPassword}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('supportPassword', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Suite idp domain hint'} style={{ flex: 1 }}>
              <TextField
                value={tenantConfig?.idpDomainHint}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('idpDomainHint', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Login prompt'} style={{ flex: 1 }}>
              <Autocomplete
                value={tenantConfig?.idpLoginPrompt}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('idpLoginPrompt', e.value)}
                options={[
                  {
                    label: 'unspecified',
                    value: 'unspecified'
                  },
                  {
                    label: 'select_account',
                    value: 'select_account'
                  },
                  {
                    label: 'login',
                    value: 'login'
                  }
                ]}
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <Checkbox
              name='employeesLogin'
              label='Skip keycloak login page'
              description='Default to employees idp'
              isChecked={tenantConfig?.idpSkipKeycloakLoginPage}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onClick={() => handleTenantConfigurationChange('idpSkipKeycloakLoginPage', !tenantConfig?.idpSkipKeycloakLoginPage)}
              value='a'
            />
            <Checkbox
              name='overridebrowserflow'
              label='Override browser flow'
              description='Replaces default flow with Embrace authentication for custom logic.'
              isChecked={tenantConfig?.idpOverrideBrowserFlow}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onClick={() => handleTenantConfigurationChange('idpOverrideBrowserFlow', !tenantConfig?.idpOverrideBrowserFlow)}
              value='a'
            />
          </Container>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>HelloId Settings</Text>
        </Container>
        <Container isVertical>
          <Container>
            <Switch
              name='helloIdEnabled'
              label='HelloId Enabled'
              value='a'
              onClick={() => handleTenantConfigurationChange('helloIdEnabled', !tenantConfig?.helloIdEnabled)}
              isChecked={tenantConfig?.helloIdEnabled}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
            />
          </Container>

          {tenantConfig?.helloIdEnabled && (
            <>
              <Container>
                <TextFieldWrapper labelText={'Client ID'}>
                  <TextField
                    isDisabled={!tenantConfig?.helloIdEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.helloIdClientId}
                    onChange={(e) => handleTenantConfigurationChange('helloIdClientId', e.target.value)}
                    type='text'
                    variant='default'
                  />
                </TextFieldWrapper>
                <TextFieldWrapper labelText={'Client Secret'}>
                  <TextField
                    isDisabled={!tenantConfig?.helloIdEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.helloIdClientSecret}
                    onChange={(e) => handleTenantConfigurationChange('helloIdClientSecret', e.target.value)}
                    type='password'
                    variant='default'
                  />
                </TextFieldWrapper>
              </Container>
              <Container>
                <TextFieldWrapper labelText={'Tenant Id'}>
                  <TextField
                    isDisabled={!tenantConfig?.helloIdEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.helloIdTenantId}
                    onChange={(e) => handleTenantConfigurationChange('helloIdTenantId', e.target.value)}
                    type='text'
                    variant='default'
                  />
                </TextFieldWrapper>
                <TextFieldWrapper></TextFieldWrapper>
              </Container>
            </>
          )}
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>Customers Settings</Text>
        </Container>
        <Container isVertical>
          <Container>
            <TextFieldWrapper labelText={'Backend hostname'} style={{ flex: 2 }}>
              <TextField
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.gateway1CustomerHostname}
                onChange={(e) => handleTenantConfigurationChange('gateway1CustomerHostname', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={'Port'} style={{ flex: 1 }}>
              <Autocomplete
                value={tenantConfig?.customersBackendPortForward}
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('customersBackendPortForward', e.value)}
                options={[
                  {
                    label: '443',
                    value: '443'
                  },
                  {
                    label: '444',
                    value: '444'
                  },
                  {
                    label: '445',
                    value: '445'
                  },
                  {
                    label: '446',
                    value: '446'
                  },
                  {
                    label: '447',
                    value: '447'
                  }
                ]}
              />
            </TextFieldWrapper>
            <TextFieldWrapper labelText='Connectivity' style={{ flex: 1 }}>
              <ConnectivityChecker hostname={tenantConfig?.gateway1CustomerHostname} port={tenantConfig?.customersBackendPortForward} />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'UI hostname'}>
              <TextField
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.customersUIHostname}
                onChange={(e) => handleTenantConfigurationChange('customersUIHostname', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>

            <TextFieldWrapper labelText={'FE version'}>
              <TextField
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.suiteVersion}
                onChange={(e) => handleTenantConfigurationChange('suiteVersion', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>

          <Container>
            <TextFieldWrapper labelText={'Portal hostname'}>
              <TextField
                value={tenantConfig?.fePortalUrl}
                onChange={(e) => handleTenantConfigurationChange('fePortalUrl', e.target.value)}
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>

            <TextFieldWrapper labelText={'API key'}>
              <TextField
                isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.gateway1CustomerAplicationId}
                onChange={(e) => handleTenantConfigurationChange('gateway1CustomerAplicationId', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
        </Container>
        <Container isVertical>
          <Container>
            <Switch
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              name='customerportaloidcenabled'
              label='Customers Portal OIDC'
              value='a'
              onClick={() => handleTenantConfigurationChange('idpCustomersPortalsEnabled', !tenantConfig?.idpCustomersPortalsEnabled)}
              isChecked={tenantConfig?.idpCustomersPortalsEnabled}
            />
          </Container>

          {tenantConfig?.idpCustomersPortalsEnabled && (
            <>
              <Container>
                <TextFieldWrapper labelText={'Idp portal hostname'}>
                  <TextField
                    isDisabled={!tenantConfig?.idpCustomersPortalsEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.idpCustomersPortalsHostname}
                    onChange={(e) => handleTenantConfigurationChange('idpCustomersPortalsHostname', e.target.value)}
                    type='text'
                    variant='default'
                  />
                </TextFieldWrapper>
                <TextFieldWrapper labelText={'Client ID'}>
                  <TextField
                    isDisabled={!tenantConfig?.idpCustomersPortalsEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.idpCustomersPortalsClientId}
                    onChange={(e) => handleTenantConfigurationChange('idpCustomersPortalsClientId', e.target.value)}
                    type='text'
                    variant='default'
                  />
                </TextFieldWrapper>
              </Container>
              <Container>
                <TextFieldWrapper labelText={'Client Secret'}>
                  <TextField
                    isDisabled={!tenantConfig?.idpCustomersPortalsEnabled || isUpdating(selectedTenant?.deployStatus)}
                    value={tenantConfig?.idpCustomersPortalsClientSecret}
                    onChange={(e) => handleTenantConfigurationChange('idpCustomersPortalsClientSecret', e.target.value)}
                    type='password'
                    variant='default'
                  />
                </TextFieldWrapper>
              </Container>
            </>
          )}
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />{' '}
        <Container>
          <Text textStyle='h6'>Shared Payments Settings</Text>
        </Container>
        <Container isVertical>
          <Container>
            <TextFieldWrapper labelText={'Shared Payments Providers'}>
              <Autocomplete
                value={tenantConfig?.sharedPaymentsProvider}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('sharedPaymentsProvider', e.value)}
                options={[
                  {
                    label: 'None',
                    value: 'None'
                  },
                  {
                    label: 'Ogone',
                    value: 'Ogone'
                  },
                  {
                    label: 'MultiSafePay',
                    value: 'MultiSafePay'
                  },
                  {
                    label: 'Mail2Pay',
                    value: 'Mail2Pay'
                  },
                  {
                    label: 'Pay',
                    value: 'Pay'
                  },
                  {
                    label: 'Emulator',
                    value: 'Emulator'
                  },
                  {
                    label: 'WorldlineDirect',
                    value: 'WorldlineDirect'
                  }
                ]}
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'config'}>
              <TextField
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                value={tenantConfig?.sharedPaymentsProviderConfiguration}
                onChange={(e) => handleTenantConfigurationChange('sharedPaymentsProviderConfiguration', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>Social Settings</Text>
        </Container>
        <Container isVertical>
          <Container>
            <Switch
              name='socialcontentmigrationenabled'
              label='Social Content Migration'
              value='a'
              onClick={() => handleTenantConfigurationChange('socialContentMigrationEnabled', !tenantConfig?.socialContentMigrationEnabled)}
              isChecked={tenantConfig?.socialContentMigrationEnabled}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
            />
          </Container>
          <Container>
            <Switch
              name='appendembraceidpscope'
              label='Append Embrace.Idp scope'
              value='a'
              onClick={() => handleTenantConfigurationChange('appendEmbraceIdpScope', !tenantConfig?.appendEmbraceIdpScope)}
              isChecked={tenantConfig?.appendEmbraceIdpScope}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
            />
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Liquit url (csp setting only)'}>
              <TextField
                value={tenantConfig?.liquitUrl}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('liquitUrl', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
          <Container>
            <TextFieldWrapper labelText={'Custom scopes (space-separated) to append to the Embrace.IdP scope'}>
              <TextField
                value={tenantConfig?.customScopes}
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
                onChange={(e) => handleTenantConfigurationChange('customScopes', e.target.value)}
                type='text'
                variant='default'
              />
            </TextFieldWrapper>
          </Container>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>Analytics</Text>
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Matomo internal hostname'} style={{ flex: 2 }}>
            <TextField
              value={tenantConfig?.matomoInteralHostname}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onChange={(e) => handleTenantConfigurationChange('matomoInteralHostname', e.target.value)}
              type='text'
              variant='default'
            />
          </TextFieldWrapper>
          <TextFieldWrapper labelText={'Site id'} style={{ flex: 1 }}>
            <TextField
              value={tenantConfig?.matomoInteralSiteId}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onChange={(e) => handleTenantConfigurationChange('matomoInteralSiteId', e.target.value)}
              type='text'
              variant='default'
            />
          </TextFieldWrapper>
          <TextFieldWrapper labelText={'Container id'} style={{ flex: 1 }}>
            <TextField
              value={tenantConfig?.matomoInternalContainerId}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onChange={(e) => handleTenantConfigurationChange('matomoInternalContainerId', e.target.value)}
              type='text'
              variant='default'
            />
          </TextFieldWrapper>
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Log Rocket API key'} style={{ flex: 1 }}>
            <TextField
              value={tenantConfig?.logRocketApiKey}
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onChange={(e) => handleTenantConfigurationChange('logRocketApiKey', e.target.value)}
              type='text'
              variant='default'
            />
          </TextFieldWrapper>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        />
        <Container>
          <Text textStyle='h6'>Feature Flags (Until App Config)</Text>
        </Container>
        <Container>
          <Container align='left' isVertical>
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-mail-module'
              label='Customers mail module'
              value='a'
              onClick={() => handleTenantConfigurationChange('customersMailEnabled', !tenantConfig?.customersMailEnabled)}
              isChecked={tenantConfig?.customersMailEnabled}
            />
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-chat-module'
              label='Customers chat module'
              value='a'
              onClick={() => handleTenantConfigurationChange('customersChatEnabled', !tenantConfig?.customersChatEnabled)}
              isChecked={tenantConfig?.customersChatEnabled}
            />
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-telephony-module'
              label='Customers telephony module'
              value='a'
              onClick={() => handleTenantConfigurationChange('customersTelephonyEnabled', !tenantConfig?.customersTelephonyEnabled)}
              isChecked={tenantConfig?.customersTelephonyEnabled}
            />
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-tasks-module'
              label='Customers tasks module'
              value='a'
              onClick={() => handleTenantConfigurationChange('customersTasksEnabled', !tenantConfig?.customersTasksEnabled)}
              isChecked={tenantConfig?.customersTasksEnabled}
            />
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-caseflow-module'
              label='Customers caseflow module'
              value='a'
              onClick={() => handleTenantConfigurationChange('customersCaseflowEnabled', !tenantConfig?.customersCaseflowEnabled)}
              isChecked={tenantConfig?.customersCaseflowEnabled}
            />
          </Container>
          <Container align='left' isVertical>
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-dossiers-module'
              label='Customers dossiers module '
              value='a'
              onClick={() => handleTenantConfigurationChange('customersDossiersEnabled', !tenantConfig?.customersDossiersEnabled)}
              isChecked={tenantConfig?.customersDossiersEnabled}
            />
            <Switch
              isDisabled={tenantConfig?.feRunMode === 'NoCustomers' || isUpdating(selectedTenant?.deployStatus)}
              name='customers-disable-chat-notifications'
              label='Customers disable chat notificatons'
              onClick={() =>
                handleTenantConfigurationChange('customersDisableChatNotifications', !tenantConfig?.customersDisableChatNotifications)
              }
              value='a'
              isChecked={tenantConfig?.customersDisableChatNotifications}
            />
            <Switch
              name='query-config-from-graph'
              label='Query config from graph'
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onClick={() => handleTenantConfigurationChange('queryConfigFromGraph', !tenantConfig?.queryConfigFromGraph)}
              value='a'
              isChecked={tenantConfig?.queryConfigFromGraph}
            />

            <Switch
              name='public-portal-theming'
              label='Public portal theming'
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onClick={() => handleTenantConfigurationChange('publicPortalThemingEnabled', !tenantConfig?.publicPortalThemingEnabled)}
              value='a'
              isChecked={tenantConfig?.publicPortalThemingEnabled}
            />
            <Switch
              name='use-latest-router'
              label='Use latest router'
              isDisabled={isUpdating(selectedTenant?.deployStatus)}
              onClick={(e) => {
                e.stopPropagation()
                handleTenantConfigurationChange('useLatestRouter', !tenantConfig?.useLatestRouter)
              }}
              value='value'
              isChecked={tenantConfig?.useLatestRouter}
            />
          </Container>
        </Container>
        <Divider
          size='100%'
          spacing={{
            bottom: 10,
            top: 10
          }}
        ></Divider>
        <Container>
          <Text textStyle='h6'>Packages</Text>
        </Container>
        <Container>
          <Container align='left' isVertical>
            <Checkbox
              isDisabled={tenantConfig?.feRunMode !== 'CustomersOnly' || isUpdating(selectedTenant?.deployStatus)}
              name='ts1'
              label='CMS Packages'
              description='Default enabled for runmodes FullSuite and NoCustomers'
              onClick={() => handleTenantConfigurationChange('enableCMSPackages', !tenantConfig?.enableCMSPackages)}
              value='a'
              isChecked={tenantConfig?.enableCMSPackages || tenantConfig?.feRunMode !== 'CustomersOnly'}
            />
            <TextFieldWrapper labelText={'Comma separated widget packs that are used by the tenant'}>
              <TextField
                value={tenantConfig?.portalWidgetPacks}
                onChange={(e) => handleTenantConfigurationChange('portalWidgetPacks', e.target.value)}
                type='text'
                variant='default'
                isDisabled={isUpdating(selectedTenant?.deployStatus)}
              />
            </TextFieldWrapper>
          </Container>
        </Container>
        {roles?.isAdmin && (
          <Modal
            isOpen={isModalOpen}
            width='45%'
            onClose={() => onModalClose()}
            onCancel={() => onModalClose()}
            hasHeaderBorder={true}
            headerTitle={`Deleting tenant '${selectedTenant?.tenantDisplayname}'`}
            height={'auto'}
            isConfirmDisabled={isClicked}
            onConfirm={onDeleteConfirm}
            variant='confirm'
            labelConfirm='Delete'
            labelCancel='Cancel'
          >
            <Text>
              {`You are about to delete ${selectedTenant?.tenantDisplayname}. `}
              <span style={{ color: 'red' }}>All resources, including data, will be permanently removed</span> for this tenant.
            </Text>
            <br />
            <Text>A confirmation email will be sent to you with a link to complete the deletion process.</Text>
          </Modal>
        )}
      </div>
    </>
  )
}

export default TenantDeploymentSettings
