import React from 'react'
import TextField from '@embracesbs/component-textfield'
import { useDebouncedCallback } from '@embracesbs/helpers'

interface SearchFieldProps {
  query: string
  setQuery: (query: string) => void
}

const SearchField: React.FC<SearchFieldProps> = ({ query, setQuery }) => {
  const onSearch = useDebouncedCallback((cs: string) => {
    setQuery(cs)
  }, 300)

  return (
    <TextField
      isSearchMode
      placeholder='Search for a subscription or tenant'
      onChange={(content) => onSearch(content.target.value)}
      onClear={() => onSearch('')}
      value={query}
      type='text'
      variant='default'
    />
  )
}

export default SearchField
