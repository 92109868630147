import React, { useEffect, useState } from 'react'
import Text from '@embracesbs/component-text'
import Badge from '@embracesbs/component-badge/Badge'
import List from '@embracesbs/component-list/List'
import ListItem from '@embracesbs/component-listitem'
import Divider from '@embracesbs/component-divider/Divider'
import { useParams } from 'react-router-dom'
import { useApiCall } from '../Utilities/ApiService/ApiService'
import { FeatureFlag } from '../Interfaces/Types'
import { sortByLabel } from '../Utilities/helpers/helpers'
import { useAccessToken } from '../Utilities/Auth/AuthService'

const labelStyles: Record<string, { color: string; icon: string }> = {
  commercial: { color: '#730039', icon: 'BusinessDealCash2' },
  development: { color: '#0b525b', icon: 'ProgrammingBrowser' }
}

export default function FeatureFlags() {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | null>()
  const [selectedItem, setSelectedItem] = useState<FeatureFlag | null>()
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()
  let params = useParams()

  useEffect(() => {
    fetchFeatureFlags()
    setSelectedItem(null)
  }, [params.segment, accessToken])

  const fetchFeatureFlags = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/featureflags/segments/${params.segment}`,
        method: 'GET'
      })
      if (response !== undefined && response.length > 0) {
        setFeatureFlags(response.sort(sortByLabel))
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  const handleItemClick = (name: string) => {
    const selected = featureFlags?.find((featureFlag) => featureFlag.name === name)
    console.log(selected)
    setSelectedItem(selected || null)
  }

  if (!featureFlags) {
    return (
      <Text>
        <b>Loading...</b>
      </Text>
    )
  } else {
    return (
      <div style={{ display: 'flex', height: '100vh', flexDirection: 'row' }}>
        <div
          style={{
            width: '30%',
            backgroundColor: '#f4f4f4',
            padding: '10px',
            overflowY: 'auto'
          }}
        >
          <List ariaLabel='Featureflags'>
            {featureFlags.map((featureFlag) => (
              <ListItem
                key={`${featureFlag.name}`}
                title={featureFlag.name}
                subTitle={`${featureFlag.label}`}
                isClickable
                onItemClick={() => handleItemClick(featureFlag.name)}
                isSelected={selectedItem?.name === featureFlag.name}
              />
            ))}
          </List>
        </div>

        <div
          style={{
            flex: 1,
            padding: '20px',
            backgroundColor: '#fff',
            overflowY: 'auto'
          }}
        >
          {selectedItem ? (
            <div style={{ padding: '10px' }}>
              <Text textStyle='h2'>{selectedItem.name}</Text>
              <Text style={{ fontStyle: 'italic', marginBottom: '10px' }}>{selectedItem.description}</Text>
              <Divider />
              <div style={{ marginTop: '10px' }}>
                {Object.entries(selectedItem?.tags || {}).map(([key, value], index) => {
                  const style = key === 'Type' ? labelStyles[value.toLowerCase()] : null
                  return (
                    <Badge
                      key={index}
                      text={`${value}`}
                      color={style ? style.color : undefined}
                      innerColor='#ffffff'
                      size={14}
                      type='status'
                    />
                  )
                })}
                <div style={{ marginTop: '20px' }}>
                  <Text textStyle='h3'>Enabled Tenants</Text>
                  {selectedItem.hasTenantFilter ? (
                    selectedItem.enabledFor && selectedItem.enabledFor.length > 0 ? (
                      <>
                        <Text>
                          Feature is enabled for <b>{selectedItem.enabledFor.length}</b>{' '}
                          {selectedItem.enabledFor.length === 1 ? 'tenant' : 'tenants'}
                        </Text>
                        <List ariaLabel='Enabled Tenants List' role='list' as='ul' gap='5px' style={{ marginTop: '10px' }}>
                          {selectedItem.enabledFor.map((tenant) => (
                            <ListItem
                              key={tenant}
                              title={tenant}
                              hoverBackgroundColor='#f0f0f0'
                              activeBackgroundColor='#e0e0e0'
                              hasCornerRadius={true}
                              style={{ color: '#000' }}
                            />
                          ))}
                        </List>
                      </>
                    ) : (
                      <Text>No tenant has this feature enabled.</Text>
                    )
                  ) : (
                    <Text>
                      Feature is globally <b>{selectedItem.enabled ? 'enabled' : 'disabled'}</b>.
                    </Text>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ padding: '20px', color: '#888' }}>
              <p>Select a feature.</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
