import React from 'react'
import Text from '@embracesbs/component-text'
import { useConfig } from '../Config/ConfigContext'
import { useSubscriptions } from '../Context/SubscriptionContext'
import { useTenants } from '../Context/TenantContext'

interface SubscriptionExtendedDetailsProbs {}

const SubscriptionExtendedDetails: React.FC<SubscriptionExtendedDetailsProbs> = ({}) => {
  const config = useConfig()
  const { selectedSubscription } = useSubscriptions()
  const { selectedTenant } = useTenants()

  if (selectedSubscription === null || selectedTenant !== null) {
    return <></>
  }

  const environments = config?.environments || []
  let pipelineRootFolder = 'unknown'
  if (environments.some((env) => ['alpha', 'stable-alpha'].includes(env))) {
    pipelineRootFolder = 'tenants-nonproduction'
  } else if (environments.some((env) => ['beta', 'production'].includes(env))) {
    pipelineRootFolder = 'tenants-prod'
  }
  return (
    <>
      <Text textStyle='h3'>{selectedSubscription.customerName}</Text>
      <br></br>
      <Text textStyle='h6'>Subscription Properties</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        CreatedBy:
      </Text>
      <Text textStyle='body'>{selectedSubscription.createdBy}</Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        CreationTime:
      </Text>
      <Text textStyle='body'>{selectedSubscription.creationTime}</Text>
      <br></br>
      {selectedSubscription.modifiedBy && (
        <>
          <Text textColor='grey' textStyle='description'>
            LastModifiedBy:
          </Text>
          <Text textStyle='body'>{selectedSubscription.modifiedBy}</Text>
          <br />
        </>
      )}
      <Text textColor='grey' textStyle='description'>
        PipelineUrl:
      </Text>
      <Text textStyle='body'>
        <a
          href={`https://dev.azure.com/embrace/Embrace%20Suite/_build?definitionScope=\\${pipelineRootFolder}\\${selectedSubscription.customerName}`}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            color: '#1a73e8',
            textDecoration: 'underline'
          }}
        >
          Azure Devops Link
        </a>
      </Text>
      <br></br>
      <Text textColor='grey' textStyle='description'>
        InternalId:
      </Text>
      <Text textStyle='body'>{selectedSubscription.id}</Text>
    </>
  )
}

export default SubscriptionExtendedDetails
