import React from 'react'
import Button2 from '@embracesbs/component-button2'
import Icon from '@embracesbs/component-icon'
import { useSubscriptions } from '../../Context/SubscriptionContext'

const BackButton: React.FC = () => {
  const { selectedSubscriptionId, setSelectedSubscriptionId } = useSubscriptions()
  if (selectedSubscriptionId === null) return null

  return (
    <Button2
      leftElement={<Icon iconName='ArrowLeft1' size={16} />}
      maxWidth='20rem'
      onClick={() => setSelectedSubscriptionId(null)}
      text=''
      tooltip='Back to subscriptions'
      type='submit'
      variant='contained'
    />
  )
}

export default BackButton
