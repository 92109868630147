import React, { useMemo } from 'react'
import List from '@embracesbs/component-list/List'
import { Tenant } from '../../Interfaces/Types'
import Text from '@embracesbs/component-text'
import Icon from '@embracesbs/component-icon'
import { itemColor } from '../../Utilities/helpers/helpers'
import Divider from '@embracesbs/component-divider'
import { useTenants } from '../Context/TenantContext'
import { useSubscriptions } from '../Context/SubscriptionContext'
import TenantListItem from './TenantListItem'

interface TenantListProps {
  query: string
}
const TenantList: React.FC<TenantListProps> = ({ query }) => {
  const { selectedSubscriptionId } = useSubscriptions()
  const { tenants, loading } = useTenants()

  const filteredTenants = useMemo(() => {
    return tenants.filter((tenant: { tenantName: string }) => tenant.tenantName.toLowerCase().includes(query.toLowerCase()))
  }, [tenants, query])

  if (selectedSubscriptionId === null) return null

  const renderList = () => {
    if (loading) {
      return (
        <Icon
          color={itemColor(undefined, false)}
          iconName='EmailActionSync1'
          size={16}
          style={{
            width: '100%',
            margin: '0 9px 0 0 ',
            animation: 'spin 1s linear infinite'
          }}
        />
      )
    }

    if (filteredTenants.length === 0) {
      return (
        <>
          <Text content={'No tenants found'} style={{ textAlign: 'center' }} textColor='grey'></Text>
          <br></br>
        </>
      )
    }

    return (
      <>
        <List ariaLabel='Test List'>
          {filteredTenants.map((item: Tenant) => (
            <TenantListItem key={item.id} tenant={item} />
          ))}
        </List>
        <br></br>
      </>
    )
  }

  return (
    <>
      <Divider
        size='100%'
        spacing={{
          bottom: 20,
          top: 0
        }}
        style={{ background: '#f2f2f4', borderColor: '#416eec' }}
        text='Tenants'
      />
      {renderList()}
    </>
  )
}

export default TenantList
