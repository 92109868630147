import React from 'react'
import AccordionList from '@embracesbs/component-accordionlist/AccordionList'
import Accordion from '@embracesbs/component-accordion/Accordion'
import Divider from '@embracesbs/component-divider'
import { RuntimeSettingId } from '../../../Interfaces/Types'
import CMSAccordionContent from './CMSAccordionContent'
import Header from '@embracesbs/component-header/Header'
import Maintenance from './Maintenance'
import NotificationsList from './NotificationsList'

interface TenantRuntimeSettingsPropss {}

const TenantRuntimeSettings: React.FC<TenantRuntimeSettingsPropss> = () => {
  return (
    <AccordionList>
      <Accordion id={RuntimeSettingId.Maintenance} headerTitle='Maintenance'>
        <Maintenance />
      </Accordion>
      <Divider />
      <Accordion id={RuntimeSettingId.notifications} headerTitle='Notifications'>
        <NotificationsList />
      </Accordion>
      <Divider />
      <Accordion id={RuntimeSettingId.CMS} headerTitle='CMS'>
        <CMSAccordionContent />
      </Accordion>
      <Divider />
    </AccordionList>
  )
}

export default TenantRuntimeSettings
