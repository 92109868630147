import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dashboard from './Pages/Dashboard'
import DeleteTenant from './Components/Tenants/DeleteTenant'
import MainMenu from './Components/MainMenu/MainMenu'
import AuthenticatedRoute from './Components/Auth/AuthenticatedRoute'
import { useIsAuthenticated } from '@azure/msal-react'
import FeatureFlagPage from './Pages/FeatureFlagPage'
import { Main, Wrapper, Aside } from './Components/StyledComponents/Components'

function App() {
  useIsAuthenticated()

  return (
    <Router>
      <Wrapper>
        <Aside>
          <MainMenu />
        </Aside>
        <Main>
          <Routes>
            <Route path='/' element={<AuthenticatedRoute component={Dashboard} />} />
            <Route path='/subscriptions' element={<AuthenticatedRoute component={Dashboard} />} />
            <Route path='/subscriptions/:subscriptionid' element={<AuthenticatedRoute component={Dashboard} />} />
            <Route path='/subscriptions/:subscriptionid/tenant/:tenantid' element={<AuthenticatedRoute component={Dashboard} />} />
            <Route path='/feature-flags/:segment' element={<AuthenticatedRoute component={FeatureFlagPage} />} />
            <Route path='/tenant/delete/:tenantId' element={<AuthenticatedRoute component={DeleteTenant} />} />
          </Routes>
        </Main>
      </Wrapper>
    </Router>
  )
}

export default App
