import React, { useEffect, useState } from 'react'
import SubscriptionList from '../Subscriptions/SubscriptionList'
import TenantList from '../Tenants/TenantList'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import Header from './Header/Header'
import SearchList from './SearchList'
import { Filters, Subscription, Tenant } from '../../Interfaces/Types'
import { useAccessToken } from '../../Utilities/Auth/AuthService'
import { useSubscriptions } from '../Context/SubscriptionContext'

const Section1: React.FC = () => {
  const [query, setQuery] = useState('')
  const [filters, setFilters] = useState<Filters>({
    environment: { options: [], selected: [] as string[] },
    deployStatus: { options: [], selected: [] as string[] }
  })
  const [searchResults, setSearchResults] = useState<{ subscriptions?: Subscription[]; tenants?: Tenant[] }>({})
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()
  const { selectedSubscriptionId } = useSubscriptions()

  useEffect(() => {
    if (accessToken) getAvailableFilters()
  }, [accessToken])

  const getAvailableFilters = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/search/availableFilters`,
        method: 'GET'
      })
      if (response) {
        setFilters({
          environment: { options: response.environments, selected: [] },
          deployStatus: { options: response.deployStatuses, selected: [] }
        })
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  useEffect(() => {
    if (query || filters.environment.selected.length > 0 || filters.deployStatus.selected.length > 0) {
      setIsSearchLoading(true)
      const environments = filters.environment.selected
      const deployStatuses = filters.deployStatus.selected

      const queryParams = new URLSearchParams({
        query,
        ...(environments.length > 0 && { environment: environments.join(',') }),
        ...(deployStatuses.length > 0 && { deployStatus: deployStatuses.join(',') })
      }).toString()

      callApiWithToken({
        endpoint: `/api/search/search?${queryParams}`,
        method: 'GET'
      })
        .then((data) => {
          setSearchResults(data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsSearchLoading(false)
        })
    } else {
      setSearchResults({})
    }
  }, [query, filters, callApiWithToken])

  const shouldShowSearchList =
    (query || filters.environment.selected.length > 0 || filters.deployStatus.selected.length > 0) && selectedSubscriptionId === null

  return (
    <>
      <Header query={query} setQuery={setQuery} filters={filters} setFilters={setFilters} />
      {shouldShowSearchList ? (
        <SearchList setQuery={setQuery} results={searchResults} isLoading={isSearchLoading} />
      ) : (
        <>
          <SubscriptionList query={query} />
          <TenantList query={query} />
        </>
      )}
    </>
  )
}

export default Section1
