import React, { useState, useEffect } from 'react'
import Modal from '@embracesbs/component-modal'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import CheckBox from '@embracesbs/component-checkbox/Checkbox'
import Container from '@embracesbs/component-container/Container'
import { Filters, SetFilters } from '../../../Interfaces/Types'

interface SelectFiltersProps {
  isOpen: boolean
  onClosed: () => void
  filters: Filters
  setFilters: SetFilters
}

const SelectFilters: React.FC<SelectFiltersProps> = ({ isOpen, onClosed, filters, setFilters }) => {
  const [localFilters, setLocalFilters] = useState<Filters>(filters)

  useEffect(() => {
    if (isOpen) {
      setLocalFilters(filters)
    }
  }, [isOpen, filters])

  const handleFilterChange = (type: 'environment' | 'deployStatus', value: string) => {
    setLocalFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters }
      const selectedIndex = updatedFilters[type].selected.indexOf(value)
      if (selectedIndex === -1) {
        updatedFilters[type].selected.push(value)
      } else {
        updatedFilters[type].selected.splice(selectedIndex, 1)
      }
      return { ...updatedFilters }
    })
  }

  const handleConfirm = () => {
    setFilters(localFilters)
    onClosed()
  }
  return (
    <Modal
      isOpen={isOpen}
      width='50%'
      onClose={() => onClosed()}
      hasHeaderBorder={true}
      headerTitle={`Select filters`}
      height={'60%'}
      onConfirm={() => handleConfirm()}
      variant='prompt'
      labelConfirm='Apply Filters'
    >
      <Container align='start'>
        <TextFieldWrapper style={{ width: '30rem' }} spacing={10} labelText='Environment:'>
          {filters.environment.options.map((filter, index) => (
            <CheckBox
              key={`environment-${filter}`}
              label={filter}
              isChecked={filters.environment.selected.includes(filter)}
              onClick={() => handleFilterChange('environment', filter)}
            />
          ))}
        </TextFieldWrapper>
        <TextFieldWrapper style={{ width: '30rem' }} spacing={10} labelText='DeployStatus:'>
          {filters.deployStatus.options.map((filter, index) => (
            <CheckBox
              key={`deploy-status-${filter}`}
              label={filter}
              isChecked={filters.deployStatus.selected.includes(filter)}
              onClick={() => handleFilterChange('deployStatus', filter)}
            />
          ))}
        </TextFieldWrapper>
      </Container>
    </Modal>
  )
}

export default SelectFilters
