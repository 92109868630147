import React, { useState } from 'react'
import Button2 from '@embracesbs/component-button2'
import Icon from '@embracesbs/component-icon'
import { useSubscriptions } from '../../Context/SubscriptionContext'
import { AddTenant, AddSubscription } from '../Modals'

const AddButton: React.FC = () => {
  const { selectedSubscriptionId, addSubscription } = useSubscriptions()
  const [tenantModalOpen, setTenantModalOpen] = useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)

  const handleClick = () => {
    selectedSubscriptionId !== null ? setTenantModalOpen(true) : setSubscriptionModalOpen(true)
  }

  const handleTenantModalClose = () => setTenantModalOpen(false)
  const handleSubscriptionModalClose = () => setSubscriptionModalOpen(false)

  return (
    <>
      <Button2
        leftElement={<Icon iconName='Add' size={16} />}
        iconSize={20}
        maxWidth='20rem'
        onClick={handleClick}
        text=''
        tooltip={selectedSubscriptionId !== null ? 'Add Tenant' : 'Add Subscription'}
        type='submit'
        variant='contained'
      />
      {tenantModalOpen && <AddTenant onCreated={(data) => console.log(data)} onClosed={handleTenantModalClose} isOpen={tenantModalOpen} />}
      {subscriptionModalOpen && (
        <AddSubscription
          onCreated={(data) => addSubscription(data)}
          onClosed={handleSubscriptionModalClose}
          isOpen={subscriptionModalOpen}
        />
      )}
    </>
  )
}

export default AddButton
