import React, { useMemo, useState } from 'react'
import Text from '@embracesbs/component-text'
import TabGroup from '@embracesbs/component-tabgroup/TabGroup'
import { TabId } from '../../Interfaces/Types'
import TenantDeploymentSettings from './TenantDeploymentSettings'
import TenantMonitoring from './TenantMonitoring'
import AuditLog from './AuditLog'
import TenantRuntimeSettings from './Runtime/TenantRunTimeSettings'
import Settings from './Settings'
import Breadcrumbs from './Breadcrumbs'
import { useTenants } from '../Context/TenantContext'
import { useSubscriptions } from '../Context/SubscriptionContext'
import NotificationsList from './Runtime/NotificationsList'
import Maintenance from './Runtime/Maintenance'
import FeatureFlag from './Runtime/FeatureFlag'

interface TenantDetailsProps {}

const TenantDetails: React.FC<TenantDetailsProps> = ({}) => {
  const { selectedSubscriptionId } = useSubscriptions()
  const { selectedTenantId, selectedTenant } = useTenants()

  const [selectedTab, setSelectedTab] = useState(1)

  const handleClick = (id: number) => {
    setSelectedTab(id)
  }

  const renderDetailsContent = useMemo(() => {
    switch (selectedTab) {
      case TabId.DeploytimeSetting:
        return <TenantDeploymentSettings />
      case TabId.RuntimeSetting:
        return <TenantRuntimeSettings />
      case TabId.FeatureFlags:
        return <FeatureFlag />
      case TabId.Monitoring:
        return <TenantMonitoring />
      case TabId.AuditLog:
        return <AuditLog />
      case TabId.Settings:
        return <Settings />
      default:
        return <div>Not yet implemented</div>
    }
  }, [selectedTab])

  const tabs = useMemo(
    () => [
      {
        id: TabId.DeploytimeSetting,
        text: 'Deploytime Settings'
      },
      {
        id: TabId.RuntimeSetting,
        text: 'Runtime Settings'
      },
      {
        id: TabId.FeatureFlags,
        text: 'Feature Flags'
      },
      {
        id: TabId.Monitoring,
        text: 'Monitoring'
      },
      {
        id: TabId.AuditLog,
        text: 'Audit Log'
      },
      {
        id: TabId.Settings,
        text: 'Tenant Properties Settings'
      }
    ],
    []
  )

  if (!selectedSubscriptionId)
    return (
      <Text align='center' textColor='grey'>
        Select 'details' of a subscription for the tenants list
      </Text>
    )
  else if (!selectedTenantId) {
    return (
      <Text align='center' textColor='grey'>
        Select 'details' of a tenant for deployment settings to show up
      </Text>
    )
  }

  if (selectedTenant?.deployStatus === 'initializing') {
    return (
      <Text align='center' textColor='grey'>
        Tenant {selectedTenant.tenantName} is initializing to view the progress press PipelineUrl link{' '}
      </Text>
    )
  }

  return (
    <>
      <Text textStyle='h6'>{selectedTenant?.tenantDisplayname}</Text>
      <Breadcrumbs />
      <br></br>
      <TabGroup onTabClick={handleClick} tabs={tabs} activeKey={1} />
      <br></br>
      <br></br>
      {renderDetailsContent}
    </>
  )
}

export default React.memo(TenantDetails)
