import React, { useState } from 'react'
import { DeployStatus, Tenant } from '../../Interfaces/Types'
import Button2 from '@embracesbs/component-button2/Button2'
import Icon from '@embracesbs/component-icon/Icon'
import { useApiCall } from '../../Utilities/ApiService/ApiService'

interface SupportLoginQuickButtonDetailsProps {
  tenant?: Tenant
}

const SupportLoginQuickButton: React.FC<SupportLoginQuickButtonDetailsProps> = ({ tenant }) => {
  const { callApiWithToken } = useApiCall()
  const [isLoading, setIsLoading] = useState(false)

  const canUpdate = (state?: DeployStatus) => {
    if (state === 'finished' || state === 'supportLoading') {
      return true
    }

    return false
  }

  const handleSupportLogin = () => {
    let supportUrl = ''
    if (tenant !== undefined) {
      setIsLoading(true)
      callApiWithToken({
        endpoint: `/api/tenant/${tenant?.id}/tenantsconfiguration/supporturl`,
        method: 'GET'
      })
        .then((data) => {
          switch (tenant?.environment) {
            case 'production':
              supportUrl = `https://auth.embracecloud.nl`
              break
            case 'beta':
              supportUrl = `https://auth.beta.embracecloud.nl`
              break
            default:
              supportUrl = `https://auth.${tenant?.environment}.embracecloud.io`
          }
          supportUrl += `/auth/realms/${tenant?.tenantName}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${data}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`
          window.open(supportUrl, '_blank', 'noopener,noreferrer')
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <>
      {canUpdate(tenant?.deployStatus) ? (
        <Button2
          className='launch-button'
          buttonSize='30px'
          leftElement={<Icon className='launch-icon' iconName='CreatedByMe' size={10} />}
          tabIndex={-1}
          onClick={handleSupportLogin}
          isLoading={isLoading}
          text=''
          tooltip='Open support login'
          type='button'
          variant='subtle'
        />
      ) : null}
    </>
  )
}

export default SupportLoginQuickButton
