import { FeatureFlag, Tenant } from '../../Interfaces/Types'

export const sortByLabel = (a: FeatureFlag, b: FeatureFlag) => {
  return a.label.localeCompare(b.label)
}

const statusColor = (item: Tenant) => {
  switch (item.deployStatus) {
    case 'initializing':
    case 'initialized':
      return '#38bdfd'
    case 'updating':
      return '#fd8138'
    case 'feWaiting':
      return '#ffc107'
    case 'notCreated':
    case 'initializingFailed':
    case 'updatingFailed':
    case 'pendingDelete':
    case 'deleting':
      return '#fd3838' // Common color for these statuses
    case 'finished':
      return '#03c509' // Green color for finished status
    case 'unknown':
    default:
      return '#ccc' // Gray color for unknown or undefined statuses
  }
}

const activeStatusColor = (item: Tenant) => {
  switch (item.deployStatus) {
    case 'initializing':
    case 'initialized':
      return '#2a93c2' // Darker blue
    case 'updating':
      return '#c25e2a' // Darker orange
    case 'feWaiting':
      return '#ffa000'
    case 'notCreated':
    case 'initializingFailed':
    case 'updatingFailed':
    case 'pendingDelete':
    case 'deleting':
      return '#c22a2a' // Darker red
    case 'finished':
      return '#029407' // Darker green
    case 'unknown':
    default:
      return '#999' // Darker gray for unknown or undefined statuses
  }
}

export const itemColor = (tenant: Tenant | undefined, isActive: boolean) => {
  if (tenant !== undefined) {
    if (isActive) {
      return activeStatusColor(tenant)
    }
    return statusColor(tenant)
  } else if (isActive) {
    return '#010cd7'
  }
  return '#416eec'
}

export const environmentLetters = (item: Tenant) => {
  switch (item.environment) {
    case 'alpha':
      return 'A'
    case 'stable-alpha':
      return 'STA'
    case 'beta':
      return 'B'
    case 'production':
      return 'P'
  }
}

export const itemBackgroundColor = (isActive: boolean) => {
  if (isActive) return '#dededf'
  return '#f2f2f4'
}
