import React, { useContext } from 'react'
import Container from '@embracesbs/component-container/Container'
import { AuthRoleContext } from '../../Auth/AuthRoleContext'
import BackButton from './BackButton'
import AddButton from './AddButton'
import SearchField from './SearchField'
import FilterButton from './FilterButton'
import { Filters, SetFilters } from '../../../Interfaces/Types'
import { useSubscriptions } from '../../Context/SubscriptionContext'

interface HeaderProps {
  query: string
  setQuery: (query: string) => void
  filters: Filters
  setFilters: SetFilters
}

const Header: React.FC<HeaderProps> = ({ query, setQuery, filters, setFilters }) => {
  const roles = useContext(AuthRoleContext)
  const { selectedSubscriptionId } = useSubscriptions()

  return (
    <Container style={{ marginBottom: '20px' }}>
      <BackButton />
      {roles?.isAdmin && selectedSubscriptionId == null && <FilterButton filters={filters} setFilters={setFilters} />}
      <SearchField query={query} setQuery={setQuery} />
      <AddButton />
    </Container>
  )
}

export default Header
