import React, { ChangeEvent, useEffect, useState } from 'react'
import NotificationItem from './NotificationItem'
import Container from '@embracesbs/component-container/Container'
import Button2 from '@embracesbs/component-button2/Button2'
import Autocomplete from '@embracesbs/component-autocomplete/Autocomplete'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield/TextField'
import { InputField, TextHeading } from '../../StyledComponents/Components'
import { Notification, PRIORITY_HIGH, PRIORITY_INFO, PRIORITY_NORMAL } from '../../../Interfaces/Types'
import DatePicker from '@embracesbs/component-datepicker/DatePicker'
import { useApiCall } from '../../../Utilities/ApiService/ApiService'
import { useAccessToken } from '../../../Utilities/Auth/AuthService'
import { useTenants } from '../../Context/TenantContext'

const style = {
  maxHeight: '90%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto', // Enables scrolling
  paddingRight: '15px'
}

const NotificationsList: React.FC = () => {
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()
  const { selectedTenantId } = useTenants()
  const [notifications, setNotifications] = useState<Notification[]>([])

  const [value, setValue] = useState({
    from: new Date(),
    to: new Date()
  })

  const [newNotification, setNewNotification] = useState({
    severity: 1,
    starttime: '12:00',
    endtime: '12:00',
    content: JSON.stringify(
      {
        en: {
          main: 'main content',
          title: 'title content'
        },
        nl: {
          main: 'main nl content',
          title: 'main nl title'
        }
      },
      null,
      2
    )
  })

  useEffect(() => {
    if (selectedTenantId !== null && accessToken !== null) fetchNotifications()
  }, [selectedTenantId, accessToken])

  const fetchNotifications = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/tenant/${selectedTenantId}/tenantsconfigurationruntime/notifications`,
        method: 'GET'
      })
      if (response !== undefined && response.length > 0) {
        setNotifications(response)
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  const formatDateString = (dateString: Date | undefined) => {
    if (dateString) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}T`

      return formattedDate
    }
  }

  const handleSubmit = () => {
    const newStartingDate = formatDateString(value?.from) + newNotification.starttime
    const newEndDate = formatDateString(value?.to) + newNotification.endtime

    const notification = {
      ...newNotification,
      starttime: newStartingDate,
      endtime: newEndDate,
      content: JSON.parse(newNotification.content)
    }

    const updatedArray = [...notifications, notification]

    updateNotifications(updatedArray)
  }

  const updateNotifications = async (updatedArray: Notification[]) => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/tenant/${selectedTenantId}/tenantsconfigurationruntime/notifications`,
        method: 'POST',
        body: updatedArray
      })
      if (response ?? response.length > 0) {
        setNotifications(response)
        setNewNotification({
          severity: 1,
          starttime: '12:00',
          endtime: '12:00',
          content: JSON.stringify(
            {
              en: {
                main: 'main content',
                title: 'title content'
              },
              nl: {
                main: 'main nl content',
                title: 'main nl title'
              }
            },
            null,
            2
          )
        })
      }
    } catch (error) {
      alert(error)
      console.log(error)
    }
  }

  const deleteNotification = (index: number) => {
    const updatedNotifications = notifications.filter((_, i) => i !== index)
    updateNotifications(updatedNotifications)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setNewNotification((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSeverityChange = (event: { label: string; value: number }) => {
    setNewNotification((prev) => ({
      ...prev,
      severity: +event.value
    }))
  }

  const priorityEnum = [
    { label: 'Info', value: PRIORITY_INFO },
    { label: 'Normal', value: PRIORITY_NORMAL },
    { label: 'High', value: PRIORITY_HIGH }
  ]

  const renderDateRange = () => {
    return (
      <TextFieldWrapper labelText={'Date range'}>
        <InputField readOnly placeholder='Select date range' value={`${value?.from.toDateString()} - ${value?.to.toDateString()}`} />
      </TextFieldWrapper>
    )
  }
  const renderDatePicker = () => {
    return (
      <DatePicker
        isRange
        selectedDayFrom={value?.from}
        selectedDayTo={value?.to}
        onSelectingDays={({ selectedDayFrom, selectedDayTo }: { selectedDayFrom: Date; selectedDayTo: Date }) => {
          const startOfFromDay = new Date(selectedDayFrom)
          startOfFromDay.setHours(0, 0, 0)
          const endOfToDay = new Date(selectedDayTo)
          endOfToDay.setHours(23, 59, 59)
          setValue({
            from: startOfFromDay,
            to: endOfToDay
          })
        }}
        isRightButtonDisabled={!value}
      />
    )
  }

  const renderNotificationsList = () => {
    return (
      <div style={style}>
        <Autocomplete value={newNotification.severity} name='severity' onChange={(e) => handleSeverityChange(e)} options={priorityEnum} />
        <Container>
          {renderDateRange()}
          {renderDatePicker()}
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Start Time'}>
            <TextField value={newNotification.starttime} onChange={handleChange} name='starttime' type='text' variant='default' />
          </TextFieldWrapper>
          <TextFieldWrapper labelText={'End Time'}>
            <TextField value={newNotification.endtime} onChange={handleChange} name='endtime' type='text' variant='default' />
          </TextFieldWrapper>
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Content'}>
            <TextField value={newNotification.content} onChange={handleChange} name='content' type='text' variant='default' />
          </TextFieldWrapper>
        </Container>
        <Container>
          <Button2 buttonRadius='8px' buttonSize='40px' text={'Add Notification'} onClick={handleSubmit} variant='contained' />
        </Container>
        <TextHeading style={{ marginBottom: '10px' }}>Notifications:</TextHeading>
        {notifications && notifications.length > 0 ? (
          <Container style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>
            {notifications &&
              notifications.map((notification, index) => (
                <NotificationItem key={index} notification={notification} deleteNotification={() => deleteNotification(index)} />
              ))}
          </Container>
        ) : (
          <TextHeading style={{ fontSize: '15px', marginLeft: '15px' }}>No notifications available.</TextHeading>
        )}
      </div>
    )
  }

  return renderNotificationsList()
}

export default NotificationsList
