import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react'
import { useConfig } from '../Config/ConfigContext'
import { useAccessToken } from '../../Utilities/Auth/AuthService'
import { useSubscriptions } from './SubscriptionContext'
import { Tenant } from '../../Interfaces/Types'
import { useNavigate } from 'react-router-dom'

const TenantContext = createContext<any>({})

interface TenantProviderProps {
  children: ReactNode
  refreshInterval?: number
}

export function TenantProvider({ children, refreshInterval = 0 }: TenantProviderProps) {
  const config = useConfig()
  const accessToken = useAccessToken()
  const { selectedSubscriptionId } = useSubscriptions()
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null)
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const history = useNavigate()

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    async function fetchTenants() {
      if (!selectedSubscriptionId || !accessToken) return

      try {
        if (isInitialFetch) {
          setLoading(true)
        }
        const response = await fetch(`${config?.host}/api/subscription/${selectedSubscriptionId}/tenants`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        })

        if (!response.ok) {
          throw new Error(`Error fetching tenants: ${response.statusText}`)
        }

        const data = await response.json()

        if (JSON.stringify(data.tenants) !== JSON.stringify(tenants)) {
          setTenants(data.tenants)
        }
        console.log('Finished fetching tenants')
      } catch (error) {
        console.error('Failed to fetch tenants', error)
      } finally {
        setLoading(false)
        setIsInitialFetch(false)
      }
    }

    if (selectedSubscriptionId) {
      fetchTenants()

      if (refreshInterval > 0) {
        interval = setInterval(() => {
          console.log('Refreshing tenants')
          fetchTenants()
        }, refreshInterval)
      }
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [config?.host, selectedSubscriptionId, refreshInterval, accessToken, isInitialFetch])

  useEffect(() => {
    if (selectedTenantId) {
      const tenant = tenants.find((tenant) => tenant.id === selectedTenantId) || null
      setSelectedTenant(tenant)
    } else {
      setSelectedTenant(null)
    }
  }, [selectedTenantId, tenants])

  useEffect(() => {
    console.log('Resetting tenants state due to subscription change')
    setTenants([])
    setSelectedTenantId(null)
    setSelectedTenant(null)
    setIsInitialFetch(true)
  }, [selectedSubscriptionId])

  const addTenant = (newTenant: Tenant) => {
    setTenants((prevTenants) => [...prevTenants, newTenant])
    setSelectedTenantId(newTenant.id)
  }

  const handleSetSelectedTenantId = (id: string | null, updateHistory: boolean = true) => {
    setSelectedTenantId(id)
    if (updateHistory) {
      if (id) {
        history(`/subscriptions/${selectedSubscriptionId}/tenant/${id}`)
      } else {
        history(`/subscriptions/${selectedSubscriptionId}`)
      }
    }
  }

  const contextValue = useMemo(
    () => ({
      tenants,
      loading,
      selectedTenantId,
      setSelectedTenantId: handleSetSelectedTenantId,
      selectedTenant,
      setSelectedTenant,
      addTenant
    }),
    [tenants, loading, selectedTenantId, selectedTenant]
  )

  return <TenantContext.Provider value={contextValue}>{children}</TenantContext.Provider>
}

export function useTenants() {
  return useContext(TenantContext)
}
