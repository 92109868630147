import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeController } from '@embracesbs/helpers'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import Loader from './Components/Loader/Loader'
import { ConfigProvider, useConfig } from './Components/Config/ConfigContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Root = () => {
  return (
    <React.StrictMode>
      <ThemeController>
        <ConfigProvider>
          <MsalInstanceProvider>
            <App />
          </MsalInstanceProvider>
        </ConfigProvider>
      </ThemeController>
    </React.StrictMode>
  )
}

function MsalInstanceProvider({ children }: { children: React.ReactNode }) {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const config = useConfig()

  useEffect(() => {
    const loadConfig = async () => {
      if (!config) return

      const msalConfig: Configuration = {
        auth: {
          clientId: config.authClientId,
          authority: config.authAuthority,
          redirectUri: window.location.origin
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false
        },
        system: {
          allowNativeBroker: false
        }
      }

      const instance = new PublicClientApplication(msalConfig)
      await instance.initialize()

      setMsalInstance(instance)
      setIsLoading(false)
    }

    loadConfig()
  }, [config])

  if (isLoading || !msalInstance) {
    return <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Authenticating...' />
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

root.render(<Root />)
reportWebVitals()
