import React, { useContext, useEffect } from 'react'
import { Tenant } from '../../Interfaces/Types'
import Button2 from '@embracesbs/component-button2/Button2'
import Icon from '@embracesbs/component-icon/Icon'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { itemColor } from '../../Utilities/helpers/helpers'
import { useTenants } from '../Context/TenantContext'
import { AuthRoleContext } from '../Auth/AuthRoleContext'

interface QuickLaunchButtonProps {
  tenant: Tenant
}

const QuickLaunchButton: React.FC<QuickLaunchButtonProps> = ({ tenant }) => {
  const roles = useContext(AuthRoleContext)
  const { selectedTenant } = useTenants()
  const { callApiWithToken } = useApiCall()

  const [isWaiting, setIsWaiting] = React.useState(false)

  useEffect(() => {}, [isWaiting])

  const handleUpdate = (tenantId: string) => {
    setIsWaiting(true)
    callApiWithToken({
      endpoint: `/api/tenantsconfiguration/${tenantId}/launch`,
      method: 'PUT',
      body: {}
    })
      .then((response: any) => {
        console.log(response)
      })
      .catch((error: any) => {
        console.error(error)
        setIsWaiting(false)
      })
  }

  const renderWaitingIcon = () => {
    tenant.deployStatus = 'feWaiting'
    return (
      <Icon
        color={itemColor(tenant, tenant.id === selectedTenant?.id)}
        iconName='EmailActionSync1'
        size={16}
        style={{
          margin: '0 9px 0 0 ',
          animation: 'spin 1s linear infinite'
        }}
      />
    )
  }

  const renderPendingIcon = () => {
    return (
      <Icon color={itemColor(tenant, tenant.id === selectedTenant?.id)} iconName='InProgress' size={16} style={{ margin: '0 9px 0 0 ' }} />
    )
  }

  const renderQuickLaunchButton = () => {
    return (
      <Button2
        className='launch-button'
        buttonSize='30px'
        leftElement={<Icon className='launch-icon' iconName='SpaceRocketFlying' size={10} />}
        tabIndex={-1}
        text=''
        onClick={() => handleUpdate(tenant.id)}
        tooltip='Quick run this tenant with the same configuration'
        type='button'
        variant='subtle'
      />
    )
  }

  const renderQuickLaunch = () => {
    if (tenant.deployStatus === 'finished' && roles?.isAdmin && !isWaiting) {
      return renderQuickLaunchButton()
    }
    if (isWaiting) {
      return renderWaitingIcon()
    }
    return renderPendingIcon()
  }

  return renderQuickLaunch()
}

export default QuickLaunchButton
