import React, { useEffect } from 'react'
import Text from '@embracesbs/component-text'
import Table from '@embracesbs/component-table'
import TableRow from '@embracesbs/component-tablerow'
import Checkbox from '@embracesbs/component-checkbox'
import { FeatureFlags } from '../../../Interfaces/Types'
import { useApiCall } from '../../../Utilities/ApiService/ApiService'
import { sortByLabel } from '../../../Utilities/helpers/helpers'
import { useAccessToken } from '../../../Utilities/Auth/AuthService'
import { useTenants } from '../../Context/TenantContext'
import Toast, { customToast } from '@embracesbs/component-toast'

const style = {
  maxHeight: '90%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto', // Enables scrolling
  paddingRight: '15px'
}

interface TenantDetailsProps {}

const FeatureFlag: React.FC<TenantDetailsProps> = ({}) => {
  const { selectedTenantId } = useTenants()
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlags[] | undefined>(undefined)
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()

  const toggleFeatureFlag = (name: string) => {
    const updatedFlags = featureFlags?.map((flag) => {
      if (flag.name === name) {
        return { ...flag, enabled: !flag.enabled }
      }
      return flag
    })

    callApiWithToken({
      endpoint: `/api/featureflags/${selectedTenantId}`,
      method: 'PUT',
      body: updatedFlags
    })
      .then((data) => {
        // When successful call the customToast.info function
        customToast.success(`${name} has been updated`)
        console.log('succes')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchFeatureFlags()
  }, [selectedTenantId, accessToken])

  const fetchFeatureFlags = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/featureflags/${selectedTenantId}?forceNoCache=true`,
        method: 'GET'
      })
      if (response !== undefined && response.length > 0) {
        setFeatureFlags(response.sort(sortByLabel))
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  if (!selectedTenantId) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  return (
    <div style={style}>
      <Toast />
      <Table style={{ justifyContent: 'space-between' }}>
        {featureFlags?.map((item, index) => (
          <TableRow key={index} style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }} hasHover={false}>
            <Text style={{ height: '100%', minWidth: '100px', flexShrink: 0 }}>{item.label}</Text>
            <div
              style={{
                flex: 1,
                minWidth: 'calc(100% - 100px)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              <Checkbox
                name={item.name}
                label={item.name}
                isChecked={item.enabled}
                isDisabled={!item.hasTenantFilter}
                description={item.description}
                onClick={() => toggleFeatureFlag(item.name)}
                value='a'
                isDescriptionSingleLine={false}
                style={{ display: 'flex', alignItems: 'center', flex: 1, whiteSpace: 'normal' }}
              />
            </div>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default FeatureFlag
