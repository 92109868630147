import React, { useState } from 'react'
import Button2 from '@embracesbs/component-button2'
import Icon from '@embracesbs/component-icon'
import { Filters, SetFilters } from '../../../Interfaces/Types'
import SelectFilters from '../Modals/SelectFilters'
import BadgeWrapper from '@embracesbs/component-badgewrapper/BadgeWrapper'
import Badge from '@embracesbs/component-badge/Badge'

interface FilterButtonProps {
  filters: Filters
  setFilters: SetFilters
}

const FilterButton: React.FC<FilterButtonProps> = ({ filters, setFilters }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const isFiltered = filters.environment.selected.length > 0 || filters.deployStatus.selected.length > 0

  return (
    <div>
      {isFiltered && (
        <BadgeWrapper
          badge={
            <Badge
              variant='counter'
              color='#fff'
              isCounterLimited={false}
              text={filters.deployStatus.selected.length + filters.environment.selected.length}
            />
          }
        >
          <Button2
            leftElement={<Icon iconName='Filter1' size={16} />}
            iconSize={20}
            maxWidth='20rem'
            onClick={() => setModalOpen(true)}
            text=''
            tooltip={'Filter by environments or deploy status'}
            type='submit'
            variant='contained'
          />
        </BadgeWrapper>
      )}
      {!isFiltered && (
        <Button2
          leftElement={<Icon iconName='Filter1' size={16} />}
          iconSize={20}
          maxWidth='20rem'
          onClick={() => setModalOpen(true)}
          text=''
          tooltip={'Filter by environments or deploy status'}
          type='submit'
          variant='contained'
        />
      )}
      <SelectFilters onClosed={() => setModalOpen(false)} isOpen={modalOpen} filters={filters} setFilters={setFilters} />
    </div>
  )
}

export default FilterButton
