import React, { useState } from 'react'
import { PostSubscription, Subscription } from '../../../Interfaces/Types'
import Modal from '@embracesbs/component-modal'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield/TextField'
import { useApiCall } from '../../../Utilities/ApiService/ApiService'

interface AddSubscriptionProps {
  isOpen: boolean
  onClosed: () => void
  onCreated: (subscription: Subscription) => void
}

const AddSubscription: React.FC<AddSubscriptionProps> = ({ isOpen, onClosed, onCreated }) => {
  const [subscriptionName, setSubscriptionName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { callApiWithToken } = useApiCall()

  const handleAddSubscription = async () => {
    const subscription: PostSubscription = {
      customerName: subscriptionName
    }

    try {
      const data = await callApiWithToken({
        endpoint: `/api/subscription`,
        method: 'POST',
        body: subscription
      })
      onCreated(data)
      onClosed()
    } catch (error: any) {
      if (error.response && error.response.status === 400 && error.response.data) {
        setErrorMessage(error.response.data)
      } else {
        setErrorMessage('An unexpected error occurred.')
      }
    }
  }

  const handleClear = () => {
    setErrorMessage('')
  }

  return (
    <Modal
      isOpen={isOpen}
      width='50%'
      onClose={() => onClosed()}
      hasHeaderBorder={true}
      headerTitle='Add Subscription'
      height={'35%'}
      onConfirm={handleAddSubscription}
      variant='prompt'
      labelConfirm='Save'
    >
      <TextFieldWrapper labelText='Subscription Name'>
        <TextField
          placeholder='Subscription Name'
          value={subscriptionName}
          onChange={(e) => setSubscriptionName(e.target.value)}
          errorMessage={errorMessage}
          hasAnError={errorMessage !== ''}
          onClear={handleClear}
        />
      </TextFieldWrapper>
    </Modal>
  )
}

export default AddSubscription
