import React from 'react'
import { BoldText, ContentText, TextContainer } from '../../StyledComponents/Components'
import Button2 from '@embracesbs/component-button2/Button2'
import { Notification } from '../../../Interfaces/Types'

interface NotificationItemProps {
  key: number
  notification: Notification
  deleteNotification: (index: number) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({ key, notification, deleteNotification }) => {
  function removeQuotes(str: string | undefined | null): string {
    if (!str) return '' // Handle cases where str is null or undefined
    return str.replace(/^"|"$/g, '')
  }

  return (
    <TextContainer key={key} className='mb-2' style={{ width: '45%', padding: '15px' }}>
      <BoldText>
        Title en: <br />
        <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.title, null, 2))}</ContentText>
      </BoldText>
      <BoldText>
        Content en: <br />
        <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.main, null, 2))}</ContentText>
      </BoldText>
      <BoldText>
        Title nl: <br />
        <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.title, null, 2))}</ContentText>
      </BoldText>
      <BoldText>
        Content nl: <br />
        <ContentText>{removeQuotes(JSON.stringify(notification?.content?.en?.main, null, 2))}</ContentText>
      </BoldText>
      <BoldText>
        Start: <br />
        <ContentText>{new Date(notification?.starttime).toLocaleString()} </ContentText>
      </BoldText>
      <BoldText>
        End: <br />
        <ContentText>{new Date(notification?.endtime).toLocaleString()}</ContentText>
      </BoldText>
      <BoldText>
        Severity: <br />
        <ContentText>{notification?.severity}</ContentText>
      </BoldText>
      <Button2
        buttonRadius='8px'
        buttonSize='40px'
        text={'Delete Notification'}
        isDanger={true}
        onClick={() => {
          deleteNotification(key)
        }}
        variant='contained'
      />
    </TextContainer>
  )
}

export default NotificationItem
