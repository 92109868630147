import { useMsal } from '@azure/msal-react'
import { useState, useEffect } from 'react'
import { useConfig } from '../../Components/Config/ConfigContext'

export const useAccessToken = () => {
  const config = useConfig()
  const { instance, inProgress, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (inProgress !== 'none') {
        console.log('Authentication in progress, skipping token acquisition.')
        return
      }
      if (!config) {
        console.log('No config available.')
        return
      }
      if (!instance) {
        console.log('No instance available.')
        return
      }
      if (accounts.length === 0) {
        console.error('No accounts detected.')
        return
      }
      if (!config?.authScope) {
        console.error('Configuration scope is not set')
        return
      }

      try {
        const response = await instance.acquireTokenSilent({
          scopes: [config.authScope],
          account: accounts[0]
        })
        setAccessToken(response.accessToken)
        localStorage.setItem('accessToken', response.accessToken)
        if (response.expiresOn) localStorage.setItem('accessTokenExpiresOn', response.expiresOn.toISOString()) // Store the expiration time as ISO string
      } catch (error: any) {
        if (error.name === 'InteractionRequiredAuthError') {
          try {
            const response = await instance.acquireTokenPopup({
              scopes: [config.authScope],
              account: accounts[0]
            })
            setAccessToken(response.accessToken)
            localStorage.setItem('accessToken', response.accessToken)
            if (response.expiresOn) localStorage.setItem('accessTokenExpiresOn', response.expiresOn.toISOString()) // Store the expiration time as ISO string
          } catch (popupError) {
            console.error('Error acquiring access token via popup:', popupError)
          }
        } else {
          console.error('Error acquiring access token silently:', error)
        }
      }
    }

    const token = localStorage.getItem('accessToken')
    const expiresOn = localStorage.getItem('accessTokenExpiresOn')

    if (token && expiresOn) {
      const now = new Date()
      const expiresOnDate = new Date(expiresOn)
      if (now < expiresOnDate) {
        setAccessToken(token)
      } else {
        fetchAccessToken()
      }
    } else {
      fetchAccessToken()
    }
  }, [instance, config, inProgress, accounts])

  return accessToken
}
